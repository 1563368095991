import React from 'react';
import './Home.css';
import { motion } from 'framer-motion';
import { About } from "../About/About";
import { Projects } from "../Projects/Projects";
import { Contact } from '../Contact/Contact';
import Typewriter from "typewriter-effect";

export const Home = () => {
  return (
    <>
      <div className='home' id="home">
        <motion.div
          className="blob-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <div className='home-text'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <h1>Hi, I'm Lorraine Rós</h1>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2, duration: 2 }}
          >
            <p>I am passionate about </p>
            <Typewriter
              onInit={(typewriter) => {
                const strings = [
                  'Web Development.',
                  'Web Design.',
                  'App Development.',
                  'App Design.',
                  'Game Development.'
                ];
      
                strings.forEach((str, index) => {
                  typewriter
                    .pauseFor(index === 0 ? 2500 : 1000)
                    .typeString(str)
                    .pauseFor(1000)
                    .deleteAll();
                });
      
                typewriter.start();
              }}
              options={{
                autoStart: true,
                loop: true,
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 4, duration: 4 }}
          >
            <p>I am willing to start my career in this dynamic field. </p>
          </motion.div>
        </div>
      </div>
      <About />
      <Projects />
      <Contact />
    </>
  )
};