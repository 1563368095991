import React from 'react';
import './Project.css';
import github from './../../assets/github.png';
import cursor from './../../assets/cursor.png';

export const Project = ({ name, description, img, code, demo, openModal }) => {
  return (
    <div className="project">
      <img alt={name} src={img} className="project-image" onClick={() => openModal(img)} />
      <h3 className="project-name">{name}</h3>
      <p className="project-description">{description}</p>
      {code ? <><a href={code} target="_blank" rel="noopener noreferrer"><button className='project-link'><img alt="github" src={github}/></button></a></> : <></>}
      {demo ? <><a href={demo} target="_blank" rel="noopener noreferrer"><button className='project-link'><img alt="demo" src={cursor}/></button></a></> : <></>}
    </div>
  );
};
