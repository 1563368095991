import { useEffect, useState } from 'react';

export const Intersection = (ref, options) => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observedRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.unobserve(observedRef);
        }
      },
      options
    );

    if (observedRef) {
      observer.observe(observedRef);
    }

    return () => {
      if (observedRef) {
        observer.unobserve(observedRef);
      }
    };
  }, [ref, options]);

  return isVisible;
};
