import React, { useRef } from 'react';
import './Contact.css';
import { motion } from 'framer-motion';
import { Intersection } from '../../components/Intersection/Intersection';
import mail from './../../assets/mail.png';
import linkedin from './../../assets/linkedin.png';

export const Contact = () => {
  const title = useRef(null);
  const card = useRef(null);

  const isTitleVisible = Intersection(title, { threshold: 1 });
  const isCardVisible = Intersection(card, { threshold: 0.8 });

  return (
    <div className="contact" id="contact">
      <div className="contact-info">
        <motion.div
          ref={title}
          initial={{ opacity: 0 }}
          animate={isTitleVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="contact-title">Contact Me</h1>
        </motion.div>
        <motion.div
          ref={card}
          initial={{ opacity: 0 }}
          animate={isCardVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <ul className='contact-list'>
            <li className='contact-list-item'>
              <img alt="mail" src={mail} className='contact-img'/>
              <a href="mailto:lorrainert2002@gmail.com"><button className='contact-src'>lorrainert2002@gmail.com</button></a>
            </li>
            <li className='contact-list-item'>
              <img alt="linkedin" src={linkedin} className='contact-img'/>
              <a href="https://www.linkedin.com/in/lorrainert/" target="_blank" rel="noopener noreferrer"><button className='contact-src'>LinkedIn</button></a>
            </li>
          </ul>
        </motion.div>
      </div>
    </div>
  )
};