import candiefy from './assets/candiefy-home.png'
import huginn from './assets/huginn-care.png'
import scav from './assets/scavcat.png'
import rod from './assets/rodMaster.png'
import movie from './assets/movie-home.png'

export const PROJECTS = [
    {
      id: 1,
      name: "Candiefy",
      description: "This is a simple e-commerce website built with React JS and CSS. It features a user-friendly interface for browsing products, managing a shopping cart, and completing the checkout process with ease.",
      img: candiefy,
      code: "https://github.com/lorraineros/Candiefy",
      demo: "https://candiefy.vercel.app/"
    },
    {
      id: 2,
      name: "Huginn Care",
      description: "This is a project my team and I did at Reykjavík University's Final Project course. The Huginn Care app, created with Jökulá, was designed to improve operations in residential care facilities. It makes the recording and management of patient data quicker. The app allows for the registration and storage of diary entries, incident reports, medication records, reports, and much more. This app was built with React Native and CSS.",
      img: huginn,
      code: "https://github.com/astaduna/HuginnCare",
      demo: ""
    },
    {
      id: 3,
      name: "Movies",
      description: "This is a responsive web application built with React and Tailwind CSS that allows users to search for movies, view detailed information about selected movies, and manage a list of favorite movies. The application integrates with a movie database API (TMDB) to fetch real-time data, including movie details, credits, trailers, and similar movies.",
      img: movie,
      code: "https://github.com/lorraineros/movies",
      demo: "https://movies-lor-proj.vercel.app/"
    },
    {
      id: 4,
      name: "Scavenger Cat",
      description: "This is a project that me and my team completed at Reykjavík University's Computer Game Design & Development course. The Scavenger Cat is a 2D Platformer game where a cat escapes home to find fresh food. Progressing from city streets to jungles, encounter enemies like evil cats and dogs. With 5 levels increasing in difficulty, including a tutorial level for basic mechanics and a fun bonus level with unique discoveries. Win by collecting all available food items.",
      img: scav,
      code: "https://github.com/gylfinn/scavenger-cat",
      demo: "https://gylfi12345.itch.io/scavenger-cat-final?secret=KqmIo3Q2Es53kKAXX0OcR4S6oU8"
    },
    {
      id: 5,
      name: "Rod Master",
      description: "This is a project that my team and I completed at Reykjavík University's Advanced Game Design & Development course. The Rod Master is a relaxing 2D game where you can move around the ocean with a boat and catch different types of fish. While catching the fish, you must avoid junks while the fish is hooked or else it might escape. The goal is to catch fish and earn money to upgrade your fishing rod. Some fishes are worth more than the other, you can look at the fish glossary and see how many fishes you’ve caught and how much their value is.",
      img: rod,
      code: "https://github.com/RedIceGuy/RodMaster",
      demo: "https://eythorh20.itch.io/rod-master"
    }
]