import React from 'react';
import './ProjectList.css';
import { Project } from '../Project/Project';

export const ProjectList = ({ projects, openModal }) => {
  return (
    <div className="project-list">
      {projects.map((project) => (
        <Project key={project.id} {...project} openModal={openModal} />
      ))}
    </div>
  );
};
