import React, { useRef } from 'react';
import './About.css';
import { motion } from 'framer-motion';
import { Intersection } from '../../components/Intersection/Intersection';
import me from './../../assets/me.jpg';
import js from './../../assets/js.png';
import cpp from './../../assets/c-.png';
import cs from './../../assets/c-sharp.png';
import html from './../../assets/html.png';
import git from './../../assets/git.png';
import node from './../../assets/node-js.png';
import react from './../../assets/react.png';
import css from './../../assets/css.png';
import tailwind from './../../assets/tailwind.png';
import sass from './../../assets/sass.png';
import python from './../../assets/python.png';
import postgre from './../../assets/postgre.png';
import docker from './../../assets/docker.png';
import dotnet from './../../assets/dotnet.png';
import unity from './../../assets/unity.png';
import unreal from './../../assets/unreal.png';

export const About = () => {
  const title = useRef(null);
  const text = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);
  const text4 = useRef(null);
  const text5 = useRef(null);
  const image = useRef(null);
  const skills = useRef(null);
  const skillsList = useRef(null);

  const isTitleVisible = Intersection(title, { threshold: 1 });
  const isTextVisible = Intersection(text, { threshold: 0.8 });
  const isText2Visible = Intersection(text2, { threshold: 0.8 });
  const isText3Visible = Intersection(text3, { threshold: 0.8 });
  const isText4Visible = Intersection(text4, { threshold: 0.8 });
  const isText5Visible = Intersection(text5, { threshold: 0.8 });
  const isImageVisible = Intersection(image, { threshold: 0.5 });
  const isSkillsVisible = Intersection(skills, { threshold: 0.5 });
  const isSkillsListVisible = Intersection(skillsList, { threshold: 0.5 });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      },
    },
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 25 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <div className="about" id="about">
      <div className="about-info">
        <motion.div
          ref={title}
          initial={{ opacity: 0 }}
          animate={isTitleVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="about-title">About <strong className="highlight">Me</strong></h1>
        </motion.div>
        <motion.div
          ref={text}
          initial={{ opacity: 0, y: 25 }}
          animate={isTextVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 25 }}
          transition={{ duration: 1 }}
        >
          <p className="about-text">
          Hello, my name is Lorraine Rós Tumarao from Reykjavík, Iceland.
          <br /><br />
          </p>
        </motion.div>
        <motion.div
          ref={text2}
          initial={{ opacity: 0, y: 25 }}
          animate={isText2Visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 25 }}
          transition={{ duration: 1 }}
        >
          <p className="about-text">
          I have BSc in Computer Science at Reykjavík University.
          <br /><br />
          </p>
        </motion.div>
        <motion.div
          ref={text3}
          initial={{ opacity: 0, y: 25 }}
          animate={isText3Visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 25 }}
          transition={{ duration: 1 }}
        >
          <p className="about-text">
          I love to create user-friendly websites and applications for users.
          <br /><br />
          </p>
        </motion.div>
        <motion.div
          ref={text4}
          initial={{ opacity: 0, y: 25 }}
          animate={isText4Visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 25 }}
          transition={{ duration: 1 }}
        >
          <p className="about-text">
          I have an interest in working on projects that involve front-end development, responsive design,
          and improving user experience across platforms.
          <br /><br />
          </p>
        </motion.div>
        <motion.div
          ref={text5}
          initial={{ opacity: 0, y: 25 }}
          animate={isText5Visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 25 }}
          transition={{ duration: 1 }}
        >
          <p className="about-text">
          I hope to be part of team where I can contribute to projects and improve my skills. 
          </p>
          <br /><br />
        </motion.div>
      </div>
      <div className="about-img-container">
        <motion.div
          ref={image}
          initial={{ opacity: 0 }}
          animate={isImageVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <img alt="me" src={me} className="about-img" />
        </motion.div>
      </div>
      <div className="about-skills">
        <motion.div
          ref={skills}
          initial={{ opacity: 0, y: 0 }}
          animate={isSkillsVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h1>Skillset</h1>
        </motion.div>
        <motion.div
          ref={skillsList}
          initial="hidden"
          animate={isSkillsListVisible ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <ul className="skills">
            <motion.li variants={itemVariants} data-skill="HTML">
              <img alt="html" src={html} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="CSS">
              <img alt="css" src={css} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="JavaScript">
              <img alt="js" src={js} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Git">
              <img alt="git" src={git} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="NodeJS">
              <img alt="nodeJS" src={node} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="React">
              <img alt="react" src={react} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Tailwind">
              <img alt="tailwind" src={tailwind} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Sass">
              <img alt="sass" src={sass} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Python">
              <img alt="python" src={python} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="C++">
              <img alt="c++" src={cpp} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="C#">
              <img alt="cs" src={cs} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="PostgreSQL">
              <img alt="postgre" src={postgre} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Docker">
              <img alt="docker" src={docker} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill=".NET">
              <img alt=".net" src={dotnet} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Unity">
              <img alt="unity" src={unity} />
            </motion.li>
            <motion.li variants={itemVariants} data-skill="Unreal">
              <img alt="unreal" src={unreal} />
            </motion.li>
          </ul>
        </motion.div>
      </div>
    </div>
  )
};