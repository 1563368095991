import React, { useRef, useState } from 'react';
import './Projects.css';
import { motion } from 'framer-motion';
import { Intersection } from '../../components/Intersection/Intersection';
import { PROJECTS } from '../../projects';
import { ProjectList } from '../../components/ProjectList/ProjectList';

export const Projects = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const title = useRef(null);
  const project = useRef(null);

  const isTitleVisible = Intersection(title, { threshold: 1 });
  const isProjectVisible = Intersection(project, { threshold: 0.1 });

  const openModal = (img) => {
    setModalImage(img);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };
  
  return (
    <div className="projects" id="projects">
      <div className="projects-info">
        <motion.div
          ref={title}
          initial={{ opacity: 0 }}
          animate={isTitleVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="projects-title">Projects</h1>
        </motion.div>
        <motion.div
          ref={project}
          initial={{ opacity: 0 }}
          animate={isProjectVisible ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
        <p className="projects-text">Here are a few projects I've had the opportunity to work on. Each project has its own unique set of tasks, challenges, and accomplishments.</p>
        <ProjectList projects={PROJECTS} openModal={openModal} />
        <p className="more-text">Want to see more? Visit my <a href="https://github.com/lorraineros" target="_blank" rel="noopener noreferrer" className="projects-link">GitHub</a>.</p>
        </motion.div>
        
      </div>
      {isModalOpen && 
      <div className="modal" onClick={closeModal}>
      <button className="close-button" onClick={closeModal}>x</button>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={modalImage} alt="Zoomed Project" className="modal-image" />
      </div>
    </div>}
    </div>
  );
};
